<template>
  <main>
    <v-card class="overflow-hidden">
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" lg="4" md="6">
            <span class="green--text">Tipo de desarmonía</span>
            <v-autocomplete
              dense
              solo
              placeholder="Seleccione un valor"
              :items="disharmonyTypes"
              :error-messages="validationErrors.disharmony_type_id"
              item-text="name"
              item-value="id"
              v-model="desarmonia.disharmony_type_id"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="6" lg="6">
            <span class="green--text">Nombre desarmonía</span>
            <v-text-field
              v-model="desarmonia.name"
              :error-messages="validationErrors.name"
              solo
              dense
              placeholder="Escriba el nombre"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <div class="text-center mt-2 mb-2">
      <v-btn small class="mx-2" color="primary" rounded @click="save()">
        Guardar
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
    </div>
  </main>
</template>

<script>
import {
  storeEventDisharmony,
  updateTypeEvent,
  eventTypesList,
} from "@/services/humansRights";
import sweetalert from "sweetalert2";
import { required } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import getEventStateColor from "../../../utils/eventStateColors";

export default {
  props: {
    mode: {
      type: String,
      default: "create",
    },
    isFemale: {
      type: Boolean,
      default: false,
    },
  },
  name: "newEvent.vue",
  data() {
    return {
      menu2: false,
      timeMenu: false,
      municipalities: [],
      territories: [],
      departments: [],
      sidewalks: [],
      zone: [],
      search: "",
      territoryTypes: [],
      disharmonyTypes: [],
      desarmonia: {
        id: "",
        name: "",
        disharmony_type_id: "",
      },
      eventId: "",
    };
  },
  validations() {
    return {
      desarmonia: {
        name: { required },
        disharmony_type_id: { required },
      },
    };
  },
  methods: {
    addAffected(affected) {
      this.desarmonia.affecteds.push(affected);
    },
    removeAffected(affected) {
      const index = this.desarmonia.affecteds.indexOf(affected);
      if (index !== -1 && affected["id"] !== undefined)
        this.desarmonia.affecteds[index]["remove"] = true;
      if (index !== -1 && affected["id"] === undefined)
        this.desarmonia.affecteds.splice(index, 1);
    },
    async save() {
      this.$v.desarmonia.$touch();
      if (this.$v.desarmonia.$invalid) return;
      try {
        this.showLoader();

        const eventRequestData = {
          name: this.desarmonia.name,
          disharmony_id: this.desarmonia.disharmony_type_id,
          is_female: this.isFemale
        };

        const storeEventResponse =
          this.mode === "edit"
            ? await updateTypeEvent(this.desarmonia.id, eventRequestData)
            : await storeEventDisharmony(eventRequestData);

        this.eventId = storeEventResponse.data.event_id;

        await sweetalert.fire(
          "Registro guardado correctamente",
          "",
          "success"
        );

        this.hideLoader();

        this.$router.push({
          path : `/humansRights/value-references/${this.isFemale}`
        });

      } catch (err) {
        this.hideLoader();
        console.error(err);
        sweetalert.fire(
          "Error",
          "ha ocurrido un error al procesar la solicitud",
          "error"
        );
      }
    },
    setEventData(eventData) {
      this.desarmonia = eventData;
    },
    eventStateColor(state) {
      return getEventStateColor(state) || "gray";
    },
  },
  async created() {
    const eventTypesResponse = await eventTypesList();
    this.disharmonyTypes = eventTypesResponse.data;
  },
  computed: {
    validationErrors() {
      return {
        name: [
          ...[
            !this.$v.desarmonia.name.required ? "este campo es requerido" : "",
          ].filter((x) => x != ""),
        ],
        disharmony_type_id: [
          ...[
            !this.$v.desarmonia.disharmony_type_id.required
              ? "este campo es requerido"
              : "",
          ].filter((x) => x != ""),
        ],
      };
    },
    ...mapState(["dataSource", "user"]),
  },
};
</script>

<style scoped></style>
